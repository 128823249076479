<template>
  <div class="table_wrapper">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      item-key="id"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Top Factories</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>

          <v-btn color="primary" class="ml-2" dark v-on:click="newItem">
            New
          </v-btn>
          <v-btn
            :color="reorder ? 'green' : 'primary'"
            class="ml-2"
            :loading="orderloading"
            dark
            v-on:click="reOrder"
          >
            {{ reorder ? "Reorder Save" : "ReOrder" }}
          </v-btn>

          <v-dialog v-model="dialogNew" persistent width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                New Factory
              </v-card-title>

              <v-layout class="m-0" wrap>
                <v-col cols="12">
                  <v-autocomplete
                    label="Please Select"
                    v-model="itemData.factory_id"
                    :items="storelist"
                    :loading="storelist.length == 0"
                    item-text="company_name"
                    item-value="member_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" v-for="(x, y) in itemData.namespace" :key="y">
                  <v-text-field
                    :label="'Slug ' + x.lang"
                    v-model="x.name"
                  ></v-text-field>
                </v-col>
              </v-layout>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogNew = false">
                  Close
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="storelist.length == 0"
                  text
                  @click="saveStore"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                v-on:click="getDataFromApi(activeTab)"
              >
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </template>
            Refresh List
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:[`item.c3`]="{ item }">
        <p v-for="(x, i) in item.c3.namespace" :key="i">{{ x.name }}</p>
      </template>

      <template v-slot:[`item.c4`]="{ item }">
        <v-btn-toggle>
          <v-btn
            color="green"
            elevation="0"
            small
            v-on:click="move(item.item.member_id, 'up')"
          >
            <v-icon small color="white">
              mdi-arrow-up
            </v-icon>
          </v-btn>
          <v-btn
            color="red"
            elevation="0"
            small
            v-on:click="move(item.item.member_id, 'down')"
          >
            <v-icon small color="white">
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
              v-on:click="editItem(item.item)"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              class="mx-1"
              fab
              small
              v-bind="attrs"
              v-on="on"
              elevation="2"
              v-on:click="deleteItem(item.item.member_id)"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "TopFactories",
  props: {
    member_id: {
      required: false,
    },
  },
  data() {
    return {
      itemData: {
        factory_id: null,
        namespace: [
          {
            lang: "tr",
            name: "",
          },
          {
            lang: "en",
            name: "",
          },
          {
            lang: "ar",
            name: "",
          },
        ],
      },
      orderloading: false,
      edit: false,
      editId: null,
      values: [],
      storelist: [],
      selected: [],
      dialogNew: false,
      dialog: false,
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "Store Name", value: "c2", sortable: false },
        { text: "Slugs", value: "c3", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      reorder: false,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    reOrder() {
      if (this.headers.find((a) => a.text == "Move")) {
        const moveIndex = this.headers.findIndex((a) => a.text == "Move");
        this.headers.splice(moveIndex, 1);
        this.reorder = false;
        this.setReOrder();
      } else {
        this.headers.unshift({ text: "Move", value: "c4", sortable: false });
        this.reorder = true;
      }
    },
    setReOrder() {
      let reOrderIndex = [];

      this.orderloading = true;

      this.desserts.forEach((element) => {
        reOrderIndex.push(element.item.member_id);
      });

      axios
        .post(process.env.VUE_APP_API_URL + "order-change", {
          data: reOrderIndex,
        })
        .then((res) => {
          //  console.log(res);
          this.orderloading = false;
        })
        .catch((err) => console.log(err));
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    move(gitem, type) {
      const indis = this.desserts.findIndex((a) => a.item.member_id == gitem);

      if (type == "up") {
        this.desserts = this.array_move(
          this.desserts,
          indis,
          indis != 0 ? indis - 1 : 0
        );
      } else {
        this.desserts = this.array_move(this.desserts, indis, indis + 1);
      }
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = "https://gowawe.com/api/admin/selected-delete/bulkdelete";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      if (this.dialogAction.type == "delete") {
        this.desserts = this.desserts.filter((item, index) => {
          return this.dialogAction.data.indexOf(item.id) == -1;
        });
      }

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          if (
            this.dialogAction.type == "approve" ||
            this.dialogAction.type == "reject"
          ) {
            this.getDataFromApi(this.activeTab);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    getParse(getjson) {
      return JSON.parse(getjson).length > 0
        ? JSON.parse(getjson)[0]
        : JSON.parse(getjson);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c2: item.company_name,
            c3: this.getParse(item.selected_infos),
            item: item,
            id: item.member_id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      const fields = [];

      if (this.search) {
        fields.push({
          field: "term",
          op: "=",
          value: this.search,
        });
      }

      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      return axios
        .get(
          process.env.VUE_APP_API_URL +
            "selected-list?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        )
        .then(function(response) {
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    newItem() {
      this.itemData = {
        factory_id: null,
        namespace: [
          {
            lang: "tr",
            name: "",
          },
          {
            lang: "en",
            name: "",
          },
          {
            lang: "ar",
            name: "",
          },
        ],
      };

      this.dialogNew = true;
      this.edit = false;
    },
    editItem(item) {
      this.edit = true;
      this.editId = item.member_id;
      const sInfo = JSON.parse(item.selected_infos);
      this.itemData = sInfo[0] ? sInfo[0] : sInfo;
      this.dialogNew = true;
      console.log(this.itemData);
    },
    deleteItem(id) {
      this.actionLock = true;
      axios
        .get(process.env.VUE_APP_API_URL + "selected-delete/" + id)
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => console.log(err));
    },
    saveStore() {
      let dataForm;
      let url;

      if (this.edit) {
        url =
          process.env.VUE_APP_API_URL +
          "update-selected?api_token=" +
          this.token;
        dataForm = {
          id: this.editId,
          is_selected: 1,
          selected_infos: [this.itemData],
        };
      } else {
        url =
          process.env.VUE_APP_API_URL + "save-selected?api_token=" + this.token;
        dataForm = {
          top_factories: [this.itemData],
        };
      }

      axios
        .post(url, dataForm)
        .then((res) => {
          this.getDataFromApi(this.activeTab);
          this.dialogNew = false;
          this.edit = false;
        })
        .catch((err) => console.log(err));
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_URL + "store-list?api_token=" + this.token)
        .then((res) => {
          this.storelist = res.data.map((a) => {
            if (
              a.company_name != null &&
              a.company_name != "" &&
              a.company_name.length > 2
            ) {
              return a;
            }
          });
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getStoreList();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
